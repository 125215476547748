import React from "react"

import blocksImage from "../images/Blocks.png"
import jumpSkipHopImage from "../images/jumpskiphop.png"
import { Helmet } from "react-helmet"

const AboutPage = () => (
  <>
    <Helmet>
      <title>About | Fraser Community Child Care Centre & Out of School Care</title>
      <link rel="canonical" href="https://frasercommunitychildcarecenter.com/about" />
    </Helmet>
    <div className="grid">
      <section className="section grid__item grid__item--center">
        <h2 className="heading heading--section">Our Program</h2>
        <p className="body-text text--pre">
          {`Fraser Community Child Care Center Operates on the philosophy of "Learning Through Play". Through both staff and child initiated play they are stimulated emotionally, socially, intellectually, and physically.
          
          Our staff program activates that encourage children based on their own individual interests and developmental levels. Activities and experiences are open ended to encourage children's creativity.`}
        </p>
      </section>

      <section className="section section--green grid__item--full grid">
        <div className="grid__item--wide">
          <p className="body-text body-text--small text--pre text--white text--semibold">
            {` The program encourages independence, social interaction, and building self-esteem and focuses on ensuring nutrition health and safety. It also offers an opportunity for children to form secure relationships with the staff members who care for them. The program understands that each child has different needs and developmental levels.
            
            Staff members are involved in communicating and working with families to ensure children’s individual needs are being met. This is done through methods such as verbal and written communication processes, program planning and developmental screening. 
            
            Daily activities are geared toward both group and individual development needs. The program also strives to link all children and families who may benefit from specialized programs with appropriate information and assistance to contact and access these resources.`}
          </p>
        </div>
      </section>

      <section className="section grid__item grid__item--center">

        <div className="grid-alt">
          <div>
            <h2 className="heading heading--section">We Offer...</h2>
            <ul className="list">
              <li className="list__item">
                Morning snack, a full lunch, and an afternoon snack following
                the Canadian Food Guide.
              </li>
              <li className="list__item">
                Accommodating hours of operation; 6:30 am – 5:45 pm.
              </li>
              <li className="list__item">
                Qualified, attentive, and caring staff.
              </li>
              <li className="list__item">
                Open door policy for parents and guardians.
              </li>
              <li className="list__item">
                Opportunity to sit on Parent/Guardian Advisory Board and attend
                Annual Policy Update Meeting.
              </li>
              <li className="list__item">
                Bus transportation to and from neighborhood schools.
              </li>
              <li className="list__item">
                Part Time and drop in services also available.
              </li>
            </ul>
          </div>
          <img src={blocksImage} style={{ maxWidth: "100%" }} alt="Blocks" />
        </div>

        <div className="grid-alt">
          <img
            className="about__image--short"
            src={jumpSkipHopImage}
            alt="Jump Skip Hop"
          />
          <p className="body-text body-text--small">
            We invite you to drop in during our hours of operation for a tour,
            to register your child, or to secure your child’s spot for a later
            date.
          </p>
        </div>

      </section>
    </div>
  </>
)

export default AboutPage
